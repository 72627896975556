$ms-refine-submenu-item-rating-line-height: 21px;
$ms-refine-submenu-item-rating-padding-left: 12px;
$ms-refine-menu-rating-margin-bottom: 10px;
$ms-refine-menu-rating-line-height: 21px;

.ms-refine-submenu__list {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

.ms-refine-submenu-item {
    display: block;
    line-height: 42px;

    .msc-swatch-container {
        display: inline-block;
        vertical-align: middle;

        &__item {
            min-width: 16px;
            height: 16px;
            margin: 0 0 0 10px;
        }
    }

    &.multi-select {
        @include add-icon($msv-Checkbox, before, 400);
    }

    &.multi-select-checked {
        @include add-icon($msv-Checkbox-Checked, before, 400);
    }

    &.single-select {
        @include add-icon($msv-RadioBtnOff, before, 400);
    }

    &.single-select-checked {
        @include add-icon($msv-RadioBtnOn, before, 400);
    }

    .refine-submenu-item {
        &__rating {
            padding-left: $ms-refine-submenu-item-rating-padding-left;
            display: inline-block;
            line-height: $ms-refine-submenu-item-rating-line-height;
        }
    }

    .msc-rating {
        line-height: $ms-refine-menu-rating-line-height;
        margin-bottom: $ms-refine-menu-rating-margin-bottom;
    }
}

.ms-refine-submenu__toggle_collapsed {
    @include add-icon($msv-Add, after);
}

.ms-refine-submenu__toggle_expanded {
    @include add-icon($msv-Remove, after);
}

.msc-choice-summary__clear-all {
    @include add-icon($msv-Cancel, after);
}
