$navigation-parent-menu-margin-left:  24px;
$navigation-parent-menu-margin-right:  24px;
$navigation-parent-menu-margin-top:  16px;
$navigation-parent-menu-margin-bottom:  16px;
$navigation-menu-list-margin-top:  0;
$navigation-menu-list-min-width:  250px;
$navigation-sub-menu-padding-left:  20px;
$navigation-sub-menu-padding-right:  20px;
$navigation-menu-item-font-size:  16px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-item-height: 48px;
$navigation-menu-item-line-height: 45px;
$navigation-menu-wrapper-height: 384px;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left:0;
$navigation-menu-max-image-height:330px;
$navigation-menu-min-image-height:300px;
$navigation-menu-max-image-width:400px;
$navigation-menu-image-width:50%;
$navigation-menu-image-padding:40px;
$navigation-menu-image-bottom:14px;
$navigation-ms-nav-list-width:100%;
$navigation-ms-nav-area-width:63%;
$navigation-ms-nav-submenu-margin-left:15px;
$navigation-ms-category-img-padding:20px;
//style presets
:root {
    --msv-nav-bg: var(--msv-bg-color);
    --msv-nav-border: #{$msv-gray-60};
    --msv-nav-carot-color: var(--msv-font-primary-color);
    --msv-nav-font-color: var(--msv-font-primary-color);
    --msv-nav-font-size: #{$navigation-menu-item-font-size};
    --msv-nav-title-font-size: #{$navigation-menu-item-font-size};
}

.ms-nav {
    background: var(--msv-nav-bg);
    color: var(--msv-nav-font-color);
    width: 100%;

    &__list {
        z-index: 1000;

        &.level-2 {
            width: 100%;
        }

        .level-2 {
            > li {
                font-weight: $navigation-menu-active-item-font-weight;
            }

            &.navmenu-multi-level {
                li.ms-nav__list__item {
                    .havesubmenu {
                        font-weight: normal;
                    }
                }
            }
        }

        &__item {
            font-size: var(--msv-nav-font-size);
            font-style: normal;
            line-height: $navigation-menu-item-line-height;
            white-space: nowrap;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .havesubmenu {
                font-weight: $navigation-menu-active-item-font-weight;
            }

            .level-3 {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .havesubmenu {
                    .ms-nav__list {
                        margin-left: $navigation-ms-nav-submenu-margin-left;
                    }
                }
            }

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    >.ms-nav__list {
        background: var(--msv-nav-bg);

        >.ms-nav__list__item {
            background: var(--msv-nav-bg);

            >.ms-nav__deafult {
                >.ms-nav__list {
                    border: 1px solid var(--msv-nav-border);
                    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.132);
                    min-width: $navigation-menu-list-min-width;
                }
            }

            >.ms-nav__feature {
                background: var(--msv-nav-bg);
                border: 1px solid var(--msv-nav-border);
                box-shadow: 0 8px 15px rgba(0, 0, 0, 0.132);
                min-height: $navigation-menu-wrapper-height;
                left: $navigation-menu-wrapper-left;
                margin-top: $navigation-menu-list-margin-top;
                min-width: $navigation-menu-list-min-width;
                position: absolute;
                outline: none;
                width: $navigation-menu-wrapper-width;

                >.category-image {
                    float: right;
                    padding: $navigation-ms-category-img-padding;

                    .category-image-title {
                        text-align: center;
                        display: none;
                    }

                    picture {
                        + div.category-image-title {
                            display: block;
                        }
                    }

                    img {
                        min-height: $navigation-menu-min-image-height;
                        max-height: $navigation-menu-max-image-height;
                        max-width: $navigation-menu-max-image-width;

                        >.ms-nav-image__item {
                            display: block;
                            padding: $navigation-menu-image-padding;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-xs) {
    .ms-nav {
        &__list {
            display: block;
            flex-wrap: nowrap;

            &__mobile__container {
                border-bottom: 1px solid var(--msv-nav-border);
                padding-bottom: 13px;
                display: flex;

                &__button {
                    @include vfi();
                    @include add-icon($msv-ChevronLeft);
                    background-color: var(--msv-bg-color);
                    border: none;
                    color: $msv-text-color;
                    font-family: $msv-primary-font-family;
                    padding-left: 0;
                    padding-right: 0;

                    &:focus {
                        outline-offset: -1px;
                    }
                }

                &__span {
                    margin-left: 21px;
                    font-weight: 600;
                    color: var(--msv-nav-font-color);
                    font-family: $msv-primary-font-family;
                    font-size: var(--msv-nav-font-size);
                }
            }

            &__item {
                &__image {
                    display: none;
                }

                &__button {
                    color: var(--msv-nav-font-color);
                    padding-left: 0;
                    padding-right: 0;
                    text-align: left;
                    width: 100%;

                    &::after {
                        @include msv-icon();
                        content: $msv-ChevronRight;
                        display: inline-flex;
                        float: right;
                        line-height: $navigation-menu-item-line-height;
                        text-align: right;
                    }
                }

                &__button,
                &__link {
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: block;
                    font-family: $msv-primary-font-family;
                    height: $navigation-menu-item-height;
                    line-height: $navigation-menu-item-line-height;

                    @include vfi();

                    &:focus {
                        outline-offset: -1px;
                    }
                }
            }
        }

        >.ms-nav__list {
            width: 100%;
            outline: none;

            .ms-nav__list {
                height: 100vh;
            }

            .havecateImage {
                float: left;
                position: relative;
                width: $navigation-ms-nav-area-width;
            }

            >.ms-nav__list__item {
                >.ms-nav__list__item__button {
                    color: var(--msv-nav-font-color);
                }
            }
        }
    }

    .ms-nav.child {
        position: absolute;
        top: 0;
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-nav {
        display: flex;

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                margin-top: $navigation-menu-list-margin-top;
                background: var(--msv-nav-bg);

                .ms-nav__list {
                    position: relative;

                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: 0;
                    }
                }
            }

            &__mobile__container {
                display: none;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            &__item {
                &__button {
                    color: var(--msv-nav-font-color);

                    &::after {
                        content: none;
                    }

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    &:focus {
                        @include vfi();
                    }

                    &[aria-expanded="true"] {
                        font-weight: $navigation-menu-active-item-font-weight;
                        text-decoration: underline;
                    }
                }
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        >.ms-nav__list {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            width: $navigation-ms-nav-list-width;

            .ms-nav__list {
                height: unset;
            }

            >.ms-nav__list__item:first-child {
                margin-left: 0;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            >.ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                    bottom: $navigation-parent-menu-margin-bottom;
                }

                >.ms-nav__list__item__button,
                .ms-nav__list__item__link {
                    @include vfi();
                    font-size: var(--msv-nav-title-font-size);
                    align-items: center;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    display: inline-flex;
                    font-family: $msv-primary-font-family;
                    justify-content: center;
                }

                .ms-nav__list__item__link {
                    color: $msv-text-color;
                    justify-content: flex-start;
                    padding-left: $navigation-sub-menu-padding-left;
                    padding-right: $navigation-sub-menu-padding-right;
                    width: 100%;
                }

                >.ms-nav__list__item__button:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
