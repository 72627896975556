$msv-notifications-success-color: rgb(16, 124, 16);
$msv-notifications-warning-color: rgb(121, 119, 117);
$msv-notifications-error-color: rgb(168, 0, 0);

// System notification styles.
.msc-notification-success {
    .msc-notification-header {
        &::before {
            @include msv-icon();
            content: $msv-success;
            padding: 8px;
            font-size: 16px;
            color: $msv-notifications-success-color;
        }
    }
}

.msc-notification-warning {
    .msc-notification-header {
        &::before {
            @include msv-icon();
            content: $msv-warning;
            padding: 8px;
            font-size: 16px;
            color: $msv-notifications-warning-color;
        }
    }
}

.msc-notification-error {
    .msc-notification-header {
        &::before {
            @include msv-icon();
            content: $msv-error;
            padding: 8px;
            font-size: 16px;
            color: $msv-notifications-error-color;
        }
    }
}

.msc-notification-loading {
    .msc-notification-title {
        display: flex;

        .ms-Spinner {
            padding: 0 8px;

            .ms-Spinner-circle {
                width: 18px;
                height: 18px;
            }
        }
    }
}
