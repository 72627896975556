$msv-primary-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue",sans-serif;
$msv-font-assistant: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$msv-font-allerta: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$msv-font-cinzel: Times, "Times New Roman", serif;
$msv-font-weight-bold: bold;
$msv-font-weight-600: 600;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-heavy: 600;
$msv-text-size: 14px;
$msv-text-line-height: 20px;
$msv-font-size-m: 16px;

$msv-heading-text-size: 20px;
$msv-heading-line-height: 28px;

$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-l: 18px;
$msv-font-size-xl: 24px;
$msv-font-size-xxl: 48px;
$msv-line-height-s: 18px;
$msv-line-height-m: 21px;
$msv-line-height-l: 24px;
$msv-line-height-xl: 31px;
$msv-line-height-xxl: 63px;

@mixin lineHeightFromFontSize($font-size) {
    @if $font-size == $msv-text-size {
        line-height: $msv-text-line-height;
    }
}

@mixin font-content-heading($font-weight: $msv-font-weight-heavy) {
    @include font-content($font-weight, $msv-heading-text-size, $msv-heading-line-height);
}

// Style presets
:root {
    // Body
    --msv-body-font-size-s: #{$msv-font-size-s};
    --msv-body-font-size-m: #{$msv-font-size-m};
    --msv-body-font-size-l: #{$msv-font-size-l};
    --msv-body-font-size-xl: #{$msv-font-size-xl};
    --msv-body-font-size-xxl: #{$msv-font-size-xxl};
}
