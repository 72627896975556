$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 20px;
$msv-details-text-margin-right: 5px;
$msv-details-container-max-width: 794px;
$msv-details-text-size-xs: 12px;

$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-order-information-half-width: 50%;
$msv-details-order-information-qr-code-margin-top: 20px;
$msv-details-order-information-qr-code-margin-bottom: 20px;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-sales-id-margin-bottom: 10px;

$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-font-weight-355: 355;

$msv-details-group-border: 1px solid $msv-black;
$msv-details-group-padding: 20px 0;
$msv-details-group-pickup-border: 1px solid $msv-gray-60;
$msv-details-group-delivery-items-text-color: $msv-gray-120;
$msv-details-group-delivery-padding-top: 20px;

$msv-details-shipping-items-label-padding-top: 30px;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-price-left: 210px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-image-size: 81px;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 81px;

$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-font-weight: 600;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;

$msv-details-button-margin-top: 12px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-heading-margin-bottom: 4px;
$msv-details-payment-method-padding-m: 20px 0 10px 0;

$msv-details-default-padding: 20px 0;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;

$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;

$msv-details-order-summary-margin-left: 340px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;

.ms-order-confirmation {
    width: 100%;
    margin: auto;
    max-width: $msv-details-container-max-width;
    position: relative;

    &__heading {
        @include font-content-xl();
        color: $msv-gray-160;
        margin-bottom: $msv-details-heading-margin-bottom;
        margin-top: $msv-details-heading-margin-top;
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;

        &-qr-code {
            align-items: center;
            background-color: var(--msv-bg-color);
            display: flex;
            height: $msv-details-order-information-qr-code-block-height;
            justify-content: center;
            margin-top: $msv-details-order-information-qr-code-margin-top;
            margin-bottom: $msv-details-order-information-qr-code-margin-bottom;
            width: $msv-details-order-information-qr-code-block-width;

            .qrcode-screenreader-text {
                clip: rect(1px, 1px, 1px, 1px);
                clip-path: inset(50%);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        &-channel-reference-id,
        &-sales-id,
        &-receipt-id &-receipt-email {
            display: block;

            @include font-content-s();
            line-height: $msv-text-line-height;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

            span {
                font-weight: $msv-font-weight-600;
            }
        }

        &-created-date {
            position: absolute;
            top: 8px;
            right: 0;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            }
        }

        &-count {
            border-right: 1px solid $msv-black;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;

        @include secondary-button();
    }

    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        padding: $msv-details-sale-lines-padding;

        &-shipping-items-label, &-picking-items-label {
            display: block;
            font-weight: $msv-font-weight-600;
        }

        &:first-child {
            border-top: none;
        }
    }

    &__groups {
        border-bottom: 1px solid $msv-gray-160;
        border-top: 1px solid $msv-gray-160;
        display: flex;
        flex-wrap: wrap;
    }

    &__group {
        &-deliveryType-pickUp, &-deliveryType-ship {
            flex: 0 0 $msv-details-order-information-half-width;
            display: flex;
            flex-direction: column;
        }

        &-delivery {
            padding-right: $msv-details-sale-line-spacing;
            padding-top: $msv-details-group-delivery-padding-top;

            &-heading {
                @include font-content-s();
                font-weight: $msv-font-weight-600;
                line-height: $msv-heading-line-height;
                margin-right: $msv-details-text-margin-right;
            }

            &-total-items {
                @include font-content-l();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line {
        margin: $msv-details-sale-line-margin;

        .msc-cart-line {
            display: block;
            width: $msv-details-sale-line-width;

            &__product {
                display: none;
            }

            &__product-image {
                margin: 0;
            }

            &__product-title {
                font-size: $msv-text-size;
                line-height: $msv-text-line-height;
            }

            &__quantity {
                .quantity-label {
                    font-size: $msv-details-text-size-xs;
                    line-height: $msv-text-line-height;
                }

                .quantity-value {
                    color: $msv-gray-120;
                    display: inline;
                    font-size: $msv-details-text-size-xs;
                    margin-left: $msv-details-text-margin-right;
                }
            }

            &__product-price, &__product-savings {
                display: none;
            }

            &__quantity.single-quantity {
                visibility: hidden;
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-button();
        }
    }

    &__order-summary {
        float: right;
        padding: $msv-details-default-padding;
        width: 50%;

        &::after {
            clear: both;
        }

        &-heading {
            display: none;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            width: 100%;
        }

        &-line-total-amount {
            border-top: 1px dashed $msv-gray-160;
            margin-bottom: $msv-details-order-summary-line-spacing;
            padding-top: $msv-details-order-summary-line-spacing;
            width: 100%;

            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-600;
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-600;
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        padding: $msv-details-default-padding;

        &-title {
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            @include vfi();
        }
    }

    &__payment-methods {
        padding: $msv-details-default-padding;
        width: 50%;
        float: left;

        &-title {
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }
    }

    &__address {
        font-weight: $msv-font-weight-355;
        margin-bottom: 6px;

        &-header {
            display: none;
        }

        &-city,
        &-threeletterisoregionname {
            &::before {
                content: "\A";
                white-space: pre;
            }
        }

        &-county {
            display: none;
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__pickup-date-timeslot {
        padding-top: 30px;
        margin-bottom: 6px;

        &-title {
            font-weight: $msv-font-weight-600;
            padding-bottom: 8px;
        }

        &-content {
            font-weight: $msv-font-weight-355;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @media screen and (min-width: $msv-container-min-width-m) {
        &__sales-lines {
            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top;
            }
        }

        &__address {
            &-phone {
                position: absolute;
            }
        }

        &__groups {
            & > div:nth-child(2n) + div::before {
                content: "";
                display: block;
                width: 200%;
                border-top: 1px solid $msv-gray-160;
            }
        }

        &__payment-methods {
            padding: $msv-details-payment-method-padding-m;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__groups {
            display: block;

            & > div {
                border-top: 1px solid $msv-gray-160;
            }

            & > div:first-of-type {
                border: none;
            }

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;

            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top-mobile;
            }
        }

        &__order-summary, &__payment-methods, &__help {
            float: none;
            padding-right: 0;
            width: 100%;
        }

        &__help, &__order-summary {
            border-top: 1px solid $msv-gray-900;
        }

        &__order-summary {
            &-heading,
            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount,
            &-line-total-amount {
                max-width: 100%;
            }
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;
        }

        .msc-cart-line__product-price {
            position: unset;
        }
    }

    @include image($msv-order-empty-image-width);

    .msc-empty_image {
        background-size: 100%;
    }
}
