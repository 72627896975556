$msv-account-profile-wrapper-margin-right: 100px;
$msv-account-profile-heading-margin-bottom: 32px;
$msv-account-profile-attributes-tile-heading-icon-padding: 5px;
$msv-account-profile-attributes-tile-heading-margin-bottom: 8px;
$msv-account-profile-attributes-button-margin-right: 5px;
$msv-account-profile-attributes-button-min-width: 164px;
$msv-account-profile-section-margin-bottom: 40px;
$msv-account-profile-section-heading-font-weight: 600;
$msv-account-profile-section-heading-margin-bottom: 4px;
$msv-account-profile-section-description-margin-bottom: 6px;
$msv-account-profile-section-margin-right-m: 0;
$msv-account-profile-preference-heading-font-weight: normal;
$msv-account-profile-preference-margin-top: 20px;
$msv-account-profile-preference-heading-margin-bottom: 10px;
$msv-account-profile-preference-description-margin-bottom: 0;

$msv-account-profile-toggle-button-icon-size: 24px;
$msv-account-profile-toggle-button-padding: 0 5px;

$msv-account-profile-attributes-toggle-min-height: 50px;
$msv-account-profile-attributes-toggle-button-height: 30px;
$msv-account-profile-attributes-toggle-button-disable-opacity: 0.6;

:root {
    // primary button
    --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);

    // toggle button
    --msv-account-profile-toggle-btn-font-color: var(--msv-accent-brand-color);
    --msv-account-profile-toggle-btn-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-toggle-size: #{$msv-account-profile-toggle-button-icon-size};
}

.ms-account-profile {
    @include font-content-m();
    align-items: flex-start;
    display: inline-flex;
    flex-direction: unset;
    justify-content: center;

    &-wrapper {
        margin-right: $msv-account-profile-wrapper-margin-right;
    }

    &__heading {
        @include font-content-xl();
        margin-bottom: $msv-account-profile-heading-margin-bottom;
    }

    &__section {
        margin-bottom: $msv-account-profile-section-margin-bottom;

        &-heading {
            @include font-content-l($msv-account-profile-section-heading-font-weight);
            margin-bottom: $msv-account-profile-section-heading-margin-bottom;
        }

        &-description {
            margin-bottom: $msv-account-profile-section-description-margin-bottom;
        }

        &-links {
            text-decoration: underline;
        }

        &-link {
            @include vfi();
        }
    }

    &__preference {
        margin-top: $msv-account-profile-preference-margin-top;

        &-heading {
            @include font-content-m($msv-account-profile-preference-heading-font-weight);
            margin-bottom: $msv-account-profile-preference-heading-margin-bottom;
        }

        &-description {
            margin-bottom: $msv-account-profile-preference-description-margin-bottom;
        }
    }

    &__toggle-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__toggle-button {
        font-size: $msv-account-profile-toggle-button-icon-size;
        background-color: transparent;
        padding: $msv-account-profile-toggle-button-padding;

        &:focus {
            @include basic-outline-offset();
        }
    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: bold;
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: bold;
        }
    }

    &__toggle-enable-button {
        @include add-icon($msv-ToggleLeft);
        color: var(--msv-accent-brand-color);
    }

    &__toggle-disable-button {
        @include add-icon($msv-ToggleRight);
    }

    .ms-account-profile__attributes {
        &-tile__heading {
            @include add-icon($msv-ListAlt, before);
            @include font-content-l($msv-font-weight-bold);
            margin-bottom: $msv-account-profile-attributes-tile-heading-margin-bottom;

            &::before {
                padding-right: $msv-account-profile-attributes-tile-heading-icon-padding;
            }
        }

        &__section {
            margin-bottom: $msv-account-profile-section-margin-bottom;

            &-heading {
                @include font-content-l($msv-account-profile-section-heading-font-weight);
                margin-bottom: $msv-account-profile-section-heading-margin-bottom;
            }

            &__toggle {
                min-height: $msv-account-profile-attributes-toggle-min-height;
            }

            &__toggle-wrapper {
                font-size: var(--msv-account-profile-toggle-btn-font-size);
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            &__toggle-button {
                font-size: var(--msv-account-profile-toggle-size);
                background-color: transparent;
                padding: $msv-account-profile-toggle-button-padding;
                height: $msv-account-profile-attributes-toggle-button-height;

                &:focus {
                    @include basic-outline-offset();
                }
            }

            &__toggle-button.disabled {
                opacity: $msv-account-profile-attributes-toggle-button-disable-opacity;
            }

            &__toggle-enable {
                .ms-account-profile__attributes__section__toggle-enable-text {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            &__toggle-disable {
                .ms-account-profile__attributes__section__toggle-disable-text {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            &__toggle-enable-button {
                @include add-icon($msv-ToggleLeft);
                color: var(--msv-accent-brand-color);
            }

            &__toggle-disable-button {
                @include add-icon($msv-ToggleRight);

                &::before {
                    transform: scale(-1, 1);
                }
            }
        }

        &-error-label {
            color: var(--msv-error-color);
        }

        &_input,
        &_select {
            width: 100%;
        }

        &_input-edit-false,
        &_select-edit-false {
            border: none;
            background: transparent;
            opacity: 1;
            color: var(--msv-account-profile-font-color);
        }

        &_select-edit-false {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
            opacity: 1;
            color: var(--msv-account-profile-font-color);
        }

        &-edit-button,
        &-save-button,
        &-cancel-button {
            @include primary-button(
                var(--msv-content-block-primary-btn-bg),
                var(--msv-content-block-primary-btn-font-color),
                var(--msv-content-block-primary-btn-border)
            );
            display: inline-block;
            font-size: $msv-font-size-s;
            line-height: $msv-text-line-height;
            min-width: $msv-account-profile-attributes-button-min-width;
            margin-right: $msv-account-profile-attributes-button-margin-right;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        display: flex;
        flex-direction: column;

        &-wrapper {
            margin-right: $msv-account-profile-section-margin-right-m;
        }

        .ms-account-profile__attributes {
            &-edit-button,
            &-save-button,
            &-cancel-button {
                margin-bottom: $msv-account-profile-attributes-button-margin-right;
            }
        }
    }
}
