$feature-heading-font-size: 32px;
$feature-heading-line-height: 43px;
$feature-link-right-margin: 16px;
$feature-links-top-margin: 32px;
$feature-link-to-link-spacing: 16px;
$feature-secondary-link-to-link-padding-top-bottom: 8px;
$feature-secondary-link-to-link-padding-left-right: 16px;
$feature-content-margin-left: 80px;
$feature-content-margin-right: 80px;
$feature-content-line-height: 21px;
$feature-content-text-margin-top: 16px;
$feature-content-button-margin-top: 32px;
$feature-cta-font-size: 14px;
$feature-cta-line-height: 20px;
$feature-content-mobile-margin-top: 16px;
$feature-content-mobile-margin-left: 16px;
$feature-content-mobile-margin-right: 16px;

:root {
    // heading
    --msv-content-block-heading-font-size: #{$feature-heading-font-size};
    --msv-content-block-heading-font-color: var(--msv-font-primary-color);

    // text
    --msv-content-block-text-font-size: var(--msv-body-font-size-m);
    --msv-content-block-textheme-dark: var(--msv-font-primary-color);

    // primary button
    --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-content-block {
    .msc-cta__primary {
        @include vfi();
        @include primary-button(var(--msv-content-block-primary-btn-bg),
        var(--msv-content-block-primary-btn-font-color), var(--msv-content-block-primary-btn-border));
    }

    &[data-m-layout="left-right"] {
        .ms-content-block__image {
            width: 100%;
        }

        .ms-content-block__details {
            margin-top: auto;
            margin-bottom: auto;
        }

        .msc-cta__primary {
            background: var(--msv-accent-brand-color);
            color: var(--msv-font-secondary-color);
            font-size: $feature-cta-font-size;
            line-height: $feature-cta-line-height;
            padding-bottom: $feature-link-to-link-spacing / 2;
            padding-left: $feature-link-to-link-spacing;
            padding-right: $feature-link-to-link-spacing;
            padding-top: $feature-link-to-link-spacing / 2;
        }

        .msc-cta__secondary {
            padding: $feature-secondary-link-to-link-padding-top-bottom
                $feature-secondary-link-to-link-padding-left-right;
        }

        .ms-content-block__title {
            color: var(--msv-content-block-heading-font-color);

            @include font-content($msv-font-weight-normal, var(--msv-content-block-heading-font-size),
            $feature-heading-line-height);
        }

        .ms-content-block__text {
            color: var(--msv-content-block-textheme-dark);

            @include font-content($msv-font-weight-normal, var(--msv-content-block-text-font-size),
            $feature-content-line-height);
            word-break: break-word;
        }

        .ms-content-block__cta {
            border-radius: 2px;
            min-width: 164px;
            display: flex;
            align-items: flex-end;
        }

        &.textplacement__left {
            .ms-content-block__details {
                .card-cta {
                    justify-content: flex-start;
                }
            }
        }

        &.textplacement__right {
            .ms-content-block__details {
                .card-cta {
                    justify-content: flex-end;
                }
            }
        }

        &.textplacement__center {
            .ms-content-block__details {
                text-align: center;

                .card-cta {
                    justify-content: center;
                }
            }
        }

        .ms-content-block__image img {
            width: 100%;
        }

        .ms-content-block__image .ms-editable-field {
            /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
            width: 100% !important;
        }

        /* body should have top margin only when there's something above it */
        * + .ms-content-block__text {
            margin-top: $feature-content-text-margin-top;
            word-break: break-word;
        }

        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $feature-content-button-margin-top;
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            &.textplacement__left,
            &.textplacement__right {
                display: grid;
                grid-template-columns: 2fr 1fr;
                grid-template-rows: auto;

                .ms-content-block__details {
                    margin-left: $feature-content-margin-left;
                    margin-right: $feature-content-margin-right;
                }
            }

            &.textplacement__center {
                .ms-content-block__details {
                    align-items: center;
                    text-align: center;
                }

                .ms-content-block__image img {
                    width: 100%;
                }

                .ms-content-block__image .ms-editable-field {
                    /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style in editorial mode. */
                    width: 100% !important;
                }
            }

            &.textplacement__left {
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-rows: auto;

                .ms-content-block__image {
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;
                    margin-bottom: auto;
                    margin-top: auto;
                }

                .ms-content-block__details {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                }
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            .ms-content-block__details {
                margin-top: $feature-content-mobile-margin-top;
            }

            .ms-content-block__image img {
                width: 100%;
            }

            .ms-content-block__image .ms-editable-field {
                /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
                width: 100% !important;
            }

            &.textplacement__left {
                .ms-content-block__details {
                    margin-left: $feature-content-mobile-margin-left;
                    margin-right: $feature-content-mobile-margin-right;
                }
            }

            &.textplacement__right {
                .ms-content-block__details {
                    margin-left: $feature-content-mobile-margin-right;
                    margin-right: $feature-content-mobile-margin-left;
                }
            }
        }
    }
}
