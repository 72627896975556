.msc-add-to-order-template-icon {
    @include add-icon($msv-file, after, $msv-outline-icon-weight);
    @include vfi();

    &__added {
        @include add-icon($msv-file, after);
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
