$msv-wishlist-item-remove-button-left: 8px;

[dir="rtl"] {
    .ms-wishlist-items {
        &__product {
            &-remove-button {
                left: $msv-wishlist-item-remove-button-left;
                right: auto;
            }
        }
    }
}
