.msc-price {
    &__strikethrough {
        color: $msv-gray-900;
        margin-right: 5px;
        text-decoration: line-through;
    }

    &.discount-value {
        .msc-price__strikethrough {
            display: block;
            margin-right: 0;
        }
    }

    .msc-price__salesagreementprice.msc-price__salesagreementprompt_expanded,.msc-price__salesagreementprompt_collapsed {
        color: var(--msv-accent-brand-color);
    }
}
