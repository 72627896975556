
$msv-store-selector-phone-section-padding-top: 5px;
$msv-store-selector-search-form-padding: 0 0 30px 0;
$msv-store-selector-search-form-margin-bottom: 5px;
$msv-store-line-header:17px;
$msv-store-locations-max-height:400px;

.ms-store-select {
    color: $msv-text-color;

    &__found-locations {
        margin-bottom: 20px;
    }

    &__search {
        display: block;
        padding: $msv-store-selector-search-form-padding;

        &-form {
            flex: auto;
            display: flex;
            height: 32px;
            position: relative;

            & .MicrosoftMap {
                & .as_container_search {
                    left: 0;
                    position: absolute;
                    top: 34px;
                    width: 100%;
                }
            }
        }

        &-see-all-stores {
            margin-top: 5px;
            cursor: pointer;
            width: fit-content;
            float: left;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        &-input {
            @include vfi();
            flex: auto;
            border: 1px solid $msv-gray-120;
            border-right: 0;
            border-radius: 2px 0 0 2px;
            padding-left: 12px;
        }

        &-button {
            cursor: pointer;

            @include add-icon($msv-Search, after);
            background-color: $msv-white;
            border: 1px solid $msv-gray-120;
            border-left: 0;
            border-radius: 0 2px 2px 0;
        }

        .search-btn-disabled {
            background-color: $msv-gray-60;
        }

        &-header {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 5px;

            &-heading {
                padding-top: 3px;
            }

            &-pickup-list {
                box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);
                background-color: $msv-gray-100;
                cursor: pointer;
                display: block;
                position: absolute;
                z-index: 9;
            }
        }
    }

    &__toggle-view {
        cursor: pointer;
        display: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    &__store-hours-details {
        display: flex;
        flex-wrap: wrap;

        .ms-store-select__store-hours-day {
            flex: 0 0 40%;
        }

        .ms-store-select__store-hours-time {
            flex: 0 0 60%;
        }
    }

    &__locations {
        max-height: $msv-store-locations-max-height;
        overflow-y: scroll;
    }

    &__location {
        padding: 20px 0;
        list-style: none;

        &-line-item {
            &-set-as-preferred-store {
                background-color: transparent;
                border: none;
                cursor: pointer;
                display: block;
                margin-top: 15px;
                outline: none;
                padding: 0;
                width: fit-content;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }

            &-preferred-store {
                background-color: transparent;
                border: none;
                cursor: pointer;
                display: block;
                font-weight: bold;
                margin-top: 15px;

                @include add-icon($msv-Checkbox-Circle-Checked, before);
                outline: none;
                padding: 0;

                &:hover, &:focus {
                    text-decoration: underline;
                }

                &::before {
                    margin-right: 10px;
                }
            }
        }

        &-line-pickup {
            &-list {
                background-color: $msv-gray-90;
                cursor: pointer;
                position: relative;
                border: 1px solid $msv-gray-30;
                z-index: 130;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $msv-gray-20;
        }

        &-line-item-header {
            margin-bottom: 10px;
        }

        &-line-item-store-name,&-line-item-store-index,
        &-line-header {
            @include font-content-s-height-l(600);
        }

        &-line-header {
            margin-left: $msv-store-line-header;
        }

        &-line-item-store-index {
            color: var(--msv-store-select-title-font-color);

            @include font-content(var(--msv-font-weight-bold),
            var(--msv-store-select-title-font-size), $msv-line-height-l);
            margin-right: 5px;

            &::after {
                content: ".";
            }
        }

        &-line-store-distance {
            color: $msv-gray-120;
        }

        &-line-item-content {
            display: flex;
            flex-direction: column;
        }

        &-line-stock-status {
            @include font-content-s-height-l();
        }

        &-line-shop-address {
            &-section {
                display: flex;
            }

            &-glyph-icon {
                padding: 2px 6px;

                @include add-icon($msv-Shop-Address);
            }
        }

        &-line-shop-phone {
            &-section {
                display: flex;
                padding-top: $msv-store-selector-phone-section-padding-top;
            }

            &-icon {
                padding: 2px 6px;

                @include add-icon($msv-Phone);
            }
        }

        &-line-select-store {
            cursor: pointer;

            @include font-content-s-height-l(600);
            background-color: #FFFFFF;
            border-radius: 2px;
            border: 1px solid $msv-gray-120;

            &:hover,
            &:focus {
                background-color: $msv-gray-20;
            }
        }
    }

    &__terms-link {
        @include vfi();
        text-decoration: underline;
    }

    &__body_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    &__store_container {
        width: 100%;
        position: relative;
    }

    &__map_container {
        display: none;
    }

    &__map {
        max-width: 1096px;

        .ms-store-select {
            &__body_wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }

            &__store_container {
                width: 59%;
            }

            &__map_container {
                width: 39%;
                display: block;

                .ms-map {
                    min-height: 100%;

                    &__body {
                        height: 100%;
                    }
                }
            }

            &__location {
                &.selected {
                    background-color: $msv-gray-60;
                }
            }
        }

        &__selected_store {
            &__modal {
                background-color: var(--msv-bg-color);
                border: 1px solid $msv-gray-120;
                bottom: -20px;
                display: inline-block;
                height: 145px;
                padding-left: 5px;
                position: absolute;
                transition: max-height 0.3s linear;
                width: 100%;
                z-index: 100;

                &.hide {
                    max-height: 0;
                }

                &.show {
                    max-height: 145px;
                    opacity: 1;
                }

                .ms-store-select {
                    &__location {
                        &-line {
                            &-pickup-list {
                                position: absolute;
                                top: -30px;
                                border: 1px solid $msv-gray-120;
                            }
                        }
                    }
                }
            }

            &__main {
                border: none;
                padding: 10px;
            }

            &__header_close_button {
                @include add-icon($msv-x-shape);
                @include vfi();
                background-color: transparent;
                border: 0;
                color: var(--msv-font-primary-color);
                font-size: 20px;
                position: absolute;
                right: 0;
                cursor: pointer;
                top: 0;
            }

            &__header_store_name {
                color: var(--msv-store-select-title-font-color);

                @include font-content(var(--msv-font-weight-bold),
                var(--msv-store-select-title-font-size), $msv-line-height-l);
            }

            &__container {
                display: flex;
                flex-direction: column;
                margin: 5px 0;

                &_product-availability {
                    @include font-content(var(--msv-font-weight-bold),
                    var(--msv-store-select-text-font-size), $msv-line-height-m);
                    margin-bottom: 5px;
                }

                &_stock-status {
                    @include font-content(var(--msv-font-weight-light),
                    var(--msv-store-select-text-font-size), $msv-line-height-m);
                    position: relative;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-store-select__location {
        &-line-item-content {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-line-item-contact-info,
        &-line-item-store-hours {
            width: 33%;
            margin-right: 1%;
        }

        &-line-item-store-availability {
            width: 32%;
            text-align: right;
        }

        &-line-stock-status {
            @include font-content-s-height-l();
            position: relative;
        }

        &-line-pickup {
            height: 55px;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-select {
        &__toggle-view {
            display: block;
            background-color: transparent;
            float: right;
            border: none;
            outline: none;
        }

        &__map {
            .ms-store-select {
                &__modal-body {
                    max-height: 75vh;
                }

                &__locations {
                    display: none;

                    &.show {
                        display: block;
                    }
                }

                &__search-header {
                    width: 100%;
                    justify-content: space-between;
                    padding-top: 8px;
                }

                &__store_container {
                    width: 100%;

                    .ms-map {
                        height: 45vh;
                    }
                }

                &__map_container {
                    width: 100%;
                }
            }
        }
    }
}
