$search-result-container-nav-height: 20px;
$search-result-container-nav-margin-bottom: 20px;
$search-result-container-nav-margin-top: 32px;
$search-result-container-nav-title-margin: 0;
$search-result-container-nav-font-size: 12px;
$search-result-container-refine-product-margin-bottom: 80px;
$search-result-container-refiner-section-padding-right: 40px;
$search-result-container-refiner-section-width: 25%;
$search-result-container-refiner-button-submenu-height: 40px;
$search-result-container-refiner-button-submenu-padding-left: 0;
$search-result-container-refiner-button-submenu-padding-right: 0;
$search-result-container-refiner-submenu-list-group-border-color: $msv-gray-160;
$search-result-container-refiner-submenu-list-group-margin-bottom: 0;
$search-result-container-refiner-submenu-list-group-margin-left: 0;
$search-result-container-refiner-submenu-item-label-padding-left: 12px;
$search-result-container-refiner-submenu-list-group-item-padding-left: 0;
$search-result-container-refiner-list-group-item-border: 1px solid rgba(0, 0, 0, 0);
$search-result-container-refiner-list-group-item-outline-offset: 2px;
$search-result-container-refiner-list-group-item-padding: 21px 12px 0 12px;
$search-result-container-refiner-slider-horizontal-background-color: $msv-gray-60;
$search-result-container-refiner-slider-horizontal-height: 4px;
$search-result-container-refiner-slider-padding: 0;
$search-result-container-refiner-slider-track-background-color: $msv-black;
$search-result-container-refiner-slider--track-top: 0;
$search-result-container-refiner-slider-thumb-background: $msv-black;
$search-result-container-refiner-slider-thumb-background-size: 12px 12px;
$search-result-container-refiner-slider-thumb-border-radius: 6px;
$search-result-container-refiner-slider-thumb-height: 12px;
$search-result-container-refiner-slider-thumb-outline-offset: 2px;
$search-result-container-refiner-slider-thumb-top: 50%;
$search-result-container-refiner-slider-thumb-width: 12px;
$search-result-container-refiner-slider-thumb-before-bottom: 0;
$search-result-container-refiner-slider-thumb-before-left: 0;
$search-result-container-refiner-slider-thumb-before-right: 0;
$search-result-container-refiner-slider-thumb-before-top: 0;
$search-result-container-refiner-slider-labels-item-font-size: 14px;
$search-result-container-refiner-slider-labels-item-margin-top: 25px;
$search-result-container-refiner-slider-labels-item-padding-bottom: 20px;
$search-result-container-pagination-padding-left: 0;
$search-result-container-product-section-width: 75%;
$search-result-container-sort-by-category-dropdown-label-margin-right: 5px;
$search-result-container-sort-by-category-dropdown-height: 32px;
$search-result-container-products-margin-bottom: 80px;
$search-result-container-products-margin-top: 80px;
$search-result-container-product-search-result-item-grid-column-gap: 20px;
$search-result-container-product-search-result-item-width: 249px;
$search-result-container-product-search-result-item-padding: 0 0 33px 0;
$search-result-container-product-search-item-title-margin-bottom: 4px;
$search-result-container-product-search-item-title-margin-top: 4px;
$search-result-container-product-search-item-rating-margin-top: 4px;
$search-result-container-page-item-previous-padding-right: 10px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-page-item-next-padding-right: 4px;
$search-result-container-margin-top-margin-top-mobile: 24px;
$search-result-container-pagination-margin-bottom-mobile: 16px;
$search-result-container-products-margin-bottom-mobile: 40px;
$search-result-container-choice-summary-padding-inline-start: 0;
$search-result-container-choice-summary-margin-left: 8px;
$search-result-container-choice-summary-margin-top: 20px;
$search-result-container-choice-summary-background-color: $msv-gray-60;
$search-result-container-choice-summary-border: 1px $msv-gray-120 solid;
$search-result-container-choice-summary-margin-right: 12px;
$search-result-container-choice-summary-margin-bottom:3px;
$search-result-container-choice-summary-border-radius: 3px;
$search-result-container-choice-summary-padding-left: 8px;
$search-result-container-choice-summary-outline-offset: 2px;
$search-result-container-choice-summary-glypg-margin-left: 8px;
$search-result-container-choice-summary-glypg-margin-right: 8px;
$search-result-modal-header-close-button-padding: 20px;
$search-result-modal-header-close-button-margin: -20px -20px -20px auto;
$search-result-modal-body-height: 450px;
$search-result-modal-body-padding: 40px 60px 10px 60px;
$search-result-modal-footer-button-background-color: $msv-black;
$search-result-modal-footer-button-border-color: $msv-black;
$search-result-modal-footer-button-padding: 12px 20px;
$search-result-modal-footer-button-min-width: 160px;
$search-result-container-refiner-submenu-list-group-border-width: 1px;
$search-result-modal-refiner-section-padding-right: 0;
$search-result-container-padding-left-mobile: 16px;
$search-result-container-padding-right-mobile: 16px;
$search-result-container-sort-by-category-top-mobile: 0;
$product-search-result_link__item-outline-width: 1px;
$product-search-result_link__item-outline-style: dashed;
$product-search-result_link__item-outline-offset:2px;
$search-result-prev-next-padding: 0 4px;
$search-result-nav-title-margin: 10px 0 5px 0;
$search-result-container-sort-by-category-flex:0 0 25%;
$search-result-container-sort-by-category-max-width:100%;
$search-result-container-sort-by-category-display:inline-block;
$search-result-container-sort-by-category-margin-bottom:20px;
$search-result-container-sort-by-category-position:relative;
$search-result-container-sort-by-category-float:right;
$search-result-list-item-width: 314px;
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ms-search-result-container__loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #f40000;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.ms-search-result-container__category-nav-section {
    display: flex;
    justify-content: space-between;
    .ms-search-result-container__category-hierarchy {
        font-size: $search-result-container-nav-font-size;
    }
    .ms-search-result-container__title {
        color: var(--msv-font-primary-color);
        margin: $search-result-container-nav-title-margin;
        h5 {
            margin: $search-result-nav-title-margin;
            position: absolute;
            right: 0;
            top: -25px;
        }
        .ms-search-result__collection-title-count {
            font-size: $search-result-container-nav-font-size;
            font-weight: normal;
        }
    }
    .ms-search-result-container__title.result-count-search {
        h5 {
            top: 0;
        }
    }
}

.ms-search-result-container__refine-product-section {
    display: inline-flex;
    margin-bottom: $search-result-container-refine-product-margin-bottom;
    width: 100%;
}

// refiner section
.ms-search-result-container__refiner-section {
    display: block;
    padding-right: $search-result-container-refiner-section-padding-right;
    width: $search-result-container-refiner-section-width;
    .collapse:not(.show) {
        display: none;
    }
    button.ms-refine-submenu__toggle_collapsed,
    .ms-refine-submenu__toggle_expanded {
        color: var(--msv-font-primary-color);
        background: transparent;
        display: flex;
        height: $search-result-container-refiner-button-submenu-height;
        justify-content: space-between;
        padding-left: $search-result-container-refiner-button-submenu-padding-left;
        padding-right: $search-result-container-refiner-button-submenu-padding-right;
        width: 100%;
    }
    .ms-refine-submenu.list-group {
        border-bottom-style: solid;
        border-bottom-width: $search-result-container-refiner-submenu-list-group-border-width;
        border-color: $search-result-container-refiner-submenu-list-group-border-color;
        display: flex;
        flex-direction: column;
        margin-bottom: $search-result-container-refiner-submenu-list-group-margin-bottom;
        padding-left: $search-result-container-refiner-submenu-list-group-margin-left;
        &:first-child {
            border-top-style: solid;
            border-top-width: $search-result-container-refiner-submenu-list-group-border-width;
            border-color: $search-result-container-refiner-submenu-list-group-border-color;
        }
        .ms-refine-submenu-item {
            padding-left: $search-result-container-refiner-submenu-list-group-item-padding-left;
            margin-bottom: 2px;
            >a {
                @include vfi();
            }
            .msc-rating {
                display: inline-block;
            }
        }
        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            @include vfi();
            display: block;
            border: 1px solid transparent;
            span.ms-refine-submenu-item__label {
                padding-left: $search-result-container-refiner-submenu-item-label-padding-left;
                vertical-align: top;
            }
        }
    }
    .list-group-item {
        border: $search-result-container-refiner-list-group-item-border;
        display: block;
        outline-offset: $search-result-container-refiner-list-group-item-outline-offset;
        padding: $search-result-container-refiner-list-group-item-padding;
        position: relative;
    }
    .slider-horizontal {
        background-color: $search-result-container-refiner-slider-horizontal-background-color;
        height: $search-result-container-refiner-slider-horizontal-height;
    }
    .slider {
        cursor: pointer;
        display: block;
        padding: $search-result-container-refiner-slider-padding;
        position: relative;
        touch-action: none;
        .slider__track {
            background-color: $search-result-container-refiner-slider-track-background-color;
            height: 100%;
            position: absolute;
            top: $search-result-container-refiner-slider--track-top;
        }
        .slider__thumb {
            @include vfi();
            background: $search-result-container-refiner-slider-thumb-background;
            background-size: $search-result-container-refiner-slider-thumb-background-size;
            border-radius: $search-result-container-refiner-slider-thumb-border-radius;
            display: inline-block;
            height: $search-result-container-refiner-slider-thumb-height;
            outline-offset: $search-result-container-refiner-slider-thumb-outline-offset;
            position: absolute;
            top: $search-result-container-refiner-slider-thumb-top;
            transform: translate3d(-50%, -50%, 0);
            width: $search-result-container-refiner-slider-thumb-width;
        }
        .slider__thumb::before {
            bottom: $search-result-container-refiner-slider-thumb-before-bottom;
            content: "";
            display: block;
            left: $search-result-container-refiner-slider-thumb-before-left;
            outline-offset: calc(0px + 0.0625rem);
            position: absolute;
            right: $search-result-container-refiner-slider-thumb-before-right;
            top: $search-result-container-refiner-slider-thumb-before-top;
        }
        .slider__labels-item {
            color: var(--msv-font-primary-color);
            cursor: pointer;
            display: inline-block;
            font-size: $search-result-container-refiner-slider-labels-item-font-size;
            margin-top: $search-result-container-refiner-slider-labels-item-margin-top;
            padding-bottom: $search-result-container-refiner-slider-labels-item-padding-bottom;
        }
        .slider__labels-item.start {
            float: left;
        }
        .slider__labels-item.end {
            float: right;
        }
    }
}

// product section
.ms-search-result-container__product-section {
    width: $search-result-container-product-section-width;
    &__title {
        color: var(--msv-font-primary-color);
    }
}

.msc-choice-summary {
    list-style-type: none;
    padding-inline-start: $search-result-container-choice-summary-padding-inline-start;
    margin-left: $search-result-container-choice-summary-margin-left;
    margin-top: $search-result-container-choice-summary-margin-top;
    .msc-choice-summary__list {
        display: inline-block;
    }
    .msc-choice-summary__list-item {
        display: inline-block;
    }
    .msc-choice-summary__item {
        @include vfi();
        background-color: $search-result-container-choice-summary-background-color;
        color: var(--msv-font-primary-color);
        border: $search-result-container-choice-summary-border;
        margin-right: $search-result-container-choice-summary-margin-right;
        margin-bottom: $search-result-container-choice-summary-margin-bottom;
        border-radius: $search-result-container-choice-summary-border-radius;
        padding-left: $search-result-container-choice-summary-padding-left;
        outline-offset: $search-result-container-choice-summary-outline-offset;
        position: relative;
    }
    .msc-choice-summary__clear-all {
        @include vfi();
    }
    .msc-choice-summary__glyph {
        margin-left: $search-result-container-choice-summary-glypg-margin-left;
        margin-right: $search-result-container-choice-summary-glypg-margin-right;
        vertical-align: text-top;
        &::after {
            @include msv-icon();
            content: $msv-Cancel;
        }
    }
    .msc-choice-summary__clear-all::after {
        display: none;
    }
}

.ms-search-result-container__Sort-by-category {
    flex: $search-result-container-sort-by-category-flex;
    max-width: $search-result-container-sort-by-category-max-width;
    display: $search-result-container-sort-by-category-display;
    position: $search-result-container-sort-by-category-position;
    float: $search-result-container-sort-by-category-float;
    top: 75px !important;
    .msc-l-dropdown {
        color: var(--msv-font-primary-color);
        background: transparent;
        border: 1px solid;
        height: $search-result-container-sort-by-category-dropdown-height;
        &__label {
            margin-right: $search-result-container-sort-by-category-dropdown-label-margin-right;
        }
    }
}

.msc-product__text {
    display: none;
}

.ms-search-result-container__Products {
    margin-bottom: $search-result-container-products-margin-bottom;
    margin-top: $search-result-container-products-margin-top;
    .ms-search-result-container__no-results-message {
        h5.error-text {
            @include font-content-m();
        }
    }
    .list-unstyled {
        display: grid;
        grid-column-gap: $search-result-container-product-search-result-item-grid-column-gap;
        grid-template-columns: repeat( auto-fill, minmax($search-result-container-product-search-result-item-width, 1fr));
    }
}

.ms-product-search-result__item {
    padding: $search-result-container-product-search-result-item-padding;
    .msc-product {
        display: block;
        @include image(312px);
    }
    .msc-product__image img {
        width: 100%;
    }
    &:hover,
    &:focus,
    &:active {
        .ms-quickView {
            &__button {
                display: block;
            }
        }
    }
}

li.ms-product-search-result__item {
    position: relative;
}


/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */

.ms-product-search-result__item a {
    .msc-product__title {
        margin-bottom: $search-result-container-product-search-item-title-margin-bottom;
        margin-top: $search-result-container-product-search-item-title-margin-top;
    }
    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-rating {
        display: block;
        margin-top: $search-result-container-product-search-item-rating-margin-top;
    }
    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &:focus {
        @include basic-outline-offset($product-search-result_link__item-outline-width, $product-search-result_link__item-outline-style, $msv-black, $product-search-result_link__item-outline-offset);
        .msc-product__title {
            text-decoration: underline;
        }
    }
}


/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */

.ms-search-result-container__category-hierarchy {
    @include vfi();
}

nav.ms-search-result-container__pagination {
    width: 100%;
}

.msc-pagination {
    display: flex;
    list-style: none;
    padding-left: $search-result-container-pagination-padding-left;
    width: 100%;
}

.previous.msc-page-item {
    display: list-item;
    flex-grow: 1;
    padding-right: $search-result-container-page-item-previous-padding-right;
    text-align: left;
    .msc-pagination__prev {
        padding-left: 4px;
        display: inline-block;
    }
    .msc-page-link {
        color: var(--msv-font-primary-color);
        &::before {
            @include msv-icon();
            content: "";
        }
        .ms-search-result__pagination-left {
            @include add-icon($msv-ChevronLeft, before);
        }
    }
}

.msc-page-item {
    padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
    display: list-item;
    flex-grow: 1;
    padding-right: $search-result-container-page-item-next-padding-right;
    text-align: right;
    .msc-pagination__next {
        padding-right: 4px;
        display: inline-block;
    }
    .msc-page-link {
        color: var(--msv-font-primary-color);
        padding-right: 4px;
        &::after {
            @include msv-icon();
            content: "";
        }
        .ms-search-result__pagination-right {
            @include add-icon($msv-ChevronRight, after);
        }
    }
}

.msc-page-item.disabled {
    .msc-page-link {
        color: $msv-gray-90;
        cursor: auto;
        pointer-events: none;
    }
}


/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */

.msc-page-link {
    @include vfi();
}

.active {
    text-decoration: underline;
}

.prev-text {
    padding: $search-result-prev-next-padding;
}

.next-text {
    padding: $search-result-prev-next-padding;
}

@media (max-width: $msv-breakpoint-l) {
    .ms-search-result-container {
        margin-top: $search-result-container-margin-top-margin-top-mobile;
        .ms-search-result-container__category-hierarchy {
            display: none;
        }
        .msc-button {
            background: none;
            border-style: none;
            float: right;
            font-weight: bold;
        }
        .msc-button:hover {
            text-decoration: underline;
        }
        .ms-product-search-result__item {
            display: inline-block;
            min-width: $search-result-list-item-width;
            vertical-align: top;
        }
    }
    .ms-search-result-container__pagination {
        margin-bottom: $search-result-container-pagination-margin-bottom-mobile;
    }
    .ms-search-result-container__Products {
        margin-bottom: $search-result-container-products-margin-bottom-mobile;
    }
    .prev-text {
        display: none;
    }
    .next-text {
        display: none;
    }
}

// modal
.msc-search-result-modal {
    .msc-review-modal-body {
        height: $search-result-modal-body-height;
        overflow-y: auto;
        padding: $search-result-modal-body-padding;
    }
    .ms-sort-and-filter-modal-close.msc-btn {
        color: var(--msv-font-secondary-color);
        background-color: $search-result-modal-footer-button-background-color;
        border-color: $search-result-modal-footer-button-border-color;
        min-width: $search-result-modal-footer-button-min-width;
    }
    .ms-search-result-container__refiner-section {
        display: block;
        padding-right: $search-result-modal-refiner-section-padding-right;
        width: 100%;
    }
    .ms-search-result-container__Sort-by-category {
        display: block;
        float: none;
        margin-bottom: $search-result-container-sort-by-category-margin-bottom;
        margin-top: 30px;
    }
}

@media (max-width: $msv-breakpoint-m) {
    .ms-search-result-container__Sort-by-category {
        position: relative;
        top: $search-result-container-sort-by-category-top-mobile;
        margin-top: 15px;
    }
    .ms-search-result-container {
        padding-left: $search-result-container-padding-left-mobile;
        padding-right: $search-result-container-padding-right-mobile;
    }
    .ms-sort-and-filter-modal-close.msc-btn {
        width: 100%;
    }
}

@media (min-width: $msv-breakpoint-xl) {
    .ms-search-result-container {
        .ms-product-search-result__item {
            .msc-product {
                @include image(334px);
            }
        }
    }
}