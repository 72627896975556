$active-image-full-width: 100%;
$active-image-details-heading-font-size-desktop: 100px;
$active-image-details-heading-font-size-mobile: 40px;
$active-image-details-heading-font-size-tablet: 60px;
$active-image-details-heading-line-height-desktop: 120px;
$active-image-details-heading-line-height-mobile: 50px;
$active-image-details-heading-line-height-tablet: 72px;
$active-image-details-text-font-size-desktop: 200px;
$active-image-details-text-font-size-mobile: 56px;
$active-image-details-text-font-size-tablet: 88px;
$active-image-details-text-line-height-desktop: 200px;
$active-image-details-text-line-height-mobile: 60px;
$active-image-details-text-line-height-tablet: 100px;
$active-image-details-additional-content-text-line-height-tablet-mobile: 40px;
$active-image-additional-content-font-weight-400: 400;
$active-image-additional-content-text-font-size: 36px;
$active-image-additional-content-text-line-height: 80px;
$active-image-product-card-image-height: 275px;
$active-image-product-card-image-width: 275px;

:root {
    //Background
    --msv-active-image-wrapper-background-color: var(--msv-bg-color);
    --msv-active-image-overlay-backgroud-color: #{$msv-black};

    //Color
    --msv-active-image-border-color: #{$msv-black};
    --msv-active-image-button-border-color: #{$msv-gray-30};
    --msv-active-image-textheme-dark: #{$msv-black};
    --msv-active-image-textheme-light: #{$msv-white};

    //Body
    --msv-active-image-text-font-size: #{$msv-heading-text-size};
    --msv-active-image-button-text-font-size: #{$msv-font-size-s};
    --msv-active-image-button-color: #{$msv-gray-30};
    --msv-active-image-details-color: #{$msv-gray-20};
    --msv-active-image-text-color: #{$msv-blue};
}

.msc-active-image {
    overflow: hidden;

    &-container {
        position: relative;

        @include image($active-image-full-width);
    }

    &-wrapper {
        position: relative;
    }

    &-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--msv-active-image-overlay-backgroud-color);
        z-index: 2;
        cursor: pointer;
        opacity: 0.4;
    }

    &-canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
    }

    &-map {
        cursor: pointer;

        area:focus {
            outline: 1px solid var(--msv-active-image-border-color);
        }
    }

    .msc-active-image-product-card-container {
        position: absolute;
        z-index: 10;

        @media (max-width: $msv-breakpoint-l) {
            /* stylelint-disable-next-line declaration-no-important -- To override the inline styles for tablets and mobile devices. */
            left: calc(50% - 160px) !important;
            /* stylelint-disable-next-line declaration-no-important -- To override the inline styles for tablets and mobile devices. */
            top: calc(50% - 255px) !important;
        }

        .msc-active-image-product-card {
            position: absolute;
            border: 1px solid var(--msv-active-image-border-color);
            z-index: 1001;

            &-wrapper {
                height: auto;
                background-color: var(--msv-active-image-wrapper-background-color);
                padding: 20px;
                text-align: center;
                max-width: 350px;
                width: 320px;

                .close-button {
                    @include add-icon($msv-x-shape);
                    @include vfi();
                    float: right;
                    background-color: transparent;
                    border: 0;
                    color: var(--msv-font-primary-color);
                    font-size: var(--msv-active-image-text-font-size);
                    cursor: pointer;
                    margin-bottom: 5px;
                }

                .msc-active-image-product__image {
                    @include image($active-image-product-card-image-width, $active-image-product-card-image-height);
                    margin-bottom: 28px;
                }
            }

            .msc-active-image-product {
                display: inline-block;
                cursor: default;
                text-decoration: none;

                .msc-active-image-product__image {
                    img {
                        display: inline-block;
                    }
                }

                .msc-active-image-product__details {
                    .msc-active-image-product__title {
                        font-family: $msv-primary-font-family;
                        font-style: normal;
                        font-weight: 500;
                        font-size: var(--msv-active-image-text-font-size);
                        line-height: $msv-line-height-l;
                    }

                    .msc-price {
                        line-height: $msv-line-height-l;
                        display: block;
                        margin-top: 12px;
                    }

                    .msc-price__strikethrough {
                        text-decoration: line-through;
                        padding-right: 5px;
                    }

                    .msc-active-image-product__text {
                        display: none;
                    }

                    .msc-rating {
                        margin-top: 12px;
                    }

                    .msc-rating__star {
                        font-size: $msv-font-size-s;
                    }

                    .msc-rating__count {
                        display: inline-flex;
                        font-size: $msv-font-size-s;
                        line-height: $msv-text-line-height;
                    }

                    .msc-rating__count::before {
                        content: "(";
                    }

                    .msc-rating__count::after {
                        content: ")";
                    }
                }
            }

            .see-more-button {
                font-family: $msv-primary-font-family;
                font-style: normal;
                font-weight: normal;
                font-size: var(--msv-active-image-button-text-font-size);
                line-height: $msv-line-height-s;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                margin-top: 18px;
                padding: 10px 40px;
                color: var(--msv-active-image-button-color);
                text-decoration: none;

                border: 1px solid var(--msv-active-image-button-border-color);
                box-sizing: border-box;
                border-radius: 100px;
            }
        }
    }

    &__details {
        align-items: flex-start;
        color: var(--msv-active-image-details-color);
        display: flex;
        height: 0;
        position: absolute;
        flex-direction: column;
        top: 0;
        z-index: 1;

        @media (max-width: $msv-breakpoint-m) {
            padding: 28px 16px;
            position: relative;
            display: table;
            margin: auto;
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            padding: 40px 24px;
            position: relative;
            display: table;
            margin: auto;
        }

        @media (min-width: $msv-breakpoint-l) {
            margin: 7% 60px 60px 60px;
        }

        &__title {
            display: contents;

            @include font-content($msv-font-weight-normal,
            $active-image-details-heading-font-size-desktop,
            $active-image-details-heading-line-height-desktop);
            color: $msv-black;

            @media (max-width: $msv-breakpoint-m) {
                @include font-content($msv-font-weight-normal,
                $active-image-details-heading-font-size-mobile,
                $active-image-details-heading-line-height-mobile);
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                @include font-content($msv-font-weight-normal,
                $active-image-details-heading-font-size-tablet,
                $active-image-details-heading-line-height-tablet);
                padding-top: 40px;
                display: block;
            }
        }

        &__text {
            margin-left: 110px;
            margin-top: -84px;

            @include font-content($msv-font-weight-normal,
            $active-image-details-text-font-size-desktop,
            $active-image-details-text-line-height-desktop);
            color: var(--msv-active-image-text-color);

            @media (max-width: $msv-breakpoint-m) {
                @include font-content($msv-font-weight-normal,
                $active-image-details-text-font-size-mobile,
                $active-image-details-text-line-height-mobile);
                margin-top: -10px;
                margin-left: 15%;
                text-align: right;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                @include font-content($msv-font-weight-normal,
                $active-image-details-text-font-size-tablet,
                $active-image-details-text-line-height-tablet);
                margin-left: 150px;
                text-align: right;
            }
        }

        &__cta {
            max-width: fit-content;
            z-index: 56;

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin: auto;
            }

            @media (max-width: $msv-breakpoint-m) {
                max-width: none;
                text-align: center;
            }

            &__link {
                margin-top: 20px;
                text-transform: uppercase;
                padding: 10px 30px;
                border: 1px solid $msv-black;
                border-radius: 100px;

                @media (max-width: $msv-breakpoint-m) {
                    margin: 40px auto;
                    width: 100%;
                    text-align: center;
                    display: block;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    margin: 40px auto;
                    width: max-content;
                }

                &:hover,
                &:focus {
                    color: var(--msv-font-primary-color);
                }
            }
        }

        &__additional-content {
            display: flex;
            font-style: normal;
            font-weight: $msv-font-weight-normal;
            flex-direction: column;
            margin-top: 40px;

            &__container {
                display: flex;
                flex-direction: row;
                margin-top: 8px;

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    margin: auto;
                }
            }

            &__paragraph {
                padding-right: 20px;
                color: $msv-black;

                @include font-content($active-image-additional-content-font-weight-400,
                $msv-font-size-m,
                $msv-line-height-l);
            }

            &__text {
                margin-left: 65px;
                color: $msv-black;

                @include font-content($active-image-additional-content-font-weight-400,
                $active-image-additional-content-text-font-size,
                $active-image-additional-content-text-line-height);
            }

            &__cta {
                &__links {
                    padding-left: 20px;
                    padding-right: 20px;

                    @include font-content(
                        $active-image-additional-content-font-weight-400,
                        $msv-font-size-s,
                        $msv-text-line-height);

                    &:nth-child(1) {
                        text-decoration-line: underline;
                        border-left: 1px solid $msv-black;
                        color: $msv-black;
                    }

                    @media (max-width: $msv-breakpoint-m) {
                        padding-right: 16px;
                    }
                }
            }

            @media (max-width: $msv-breakpoint-m) {
                position: relative;
                margin-top: 20px;

                &__text {
                    margin-left: 60px;

                    @include font-content(
                        $active-image-additional-content-font-weight-400,
                        $msv-font-size-xl,
                        $active-image-details-additional-content-text-line-height-tablet-mobile);
                }

                &-cta {
                    text-align: left;

                    &-links {
                        &:nth-child(1) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }

                        &:nth-child(2) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }
                    }
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                position: relative;
                margin-top: 15px;

                &__text {
                    @include font-content(
                        $active-image-additional-content-font-weight-400,
                        $msv-font-size-xl,
                        $active-image-details-additional-content-text-line-height-tablet-mobile);
                    margin: auto;
                    padding-bottom: 10px;
                }

                &-cta {
                    text-align: left;

                    &-links {
                        &:nth-child(1) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }

                        &:nth-child(2) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }
                    }
                }
            }
        }
    }

    &.texttheme__light {
        background: var(--msv-active-image-textheme-dark);

        .msc-active-image__details {
            color: var(--msv-active-image-textheme-light);

            &__title {
                color: var(--msv-active-image-textheme-light);
            }

            &__text {
                color: var(--msv-active-image-textheme-light);
            }

            &__cta {
                &__link {
                    border-color: var(--msv-active-image-textheme-light);
                    color: var(--msv-active-image-textheme-light);
                }
            }

            &__additional-content {
                &__paragraph {
                    color: var(--msv-active-image-textheme-light);
                }

                &__text {
                    color: var(--msv-active-image-textheme-light);
                }
            }
        }
    }

    &.texttheme__dark {
        background: var(--msv-active-image-textheme-light);

        .msc-active-image__details {
            color: var(--msv-active-image-textheme-dark);

            &__title {
                color: var(--msv-active-image-textheme-dark);
            }

            &__text {
                color: var(--msv-active-image-textheme-dark);
            }

            &__cta {
                &__link {
                    border-color: var(--msv-active-image-textheme-dark);
                    color: var(--msv-active-image-textheme-dark);
                }
            }

            &__additional-content {
                &__paragraph {
                    color: var(--msv-active-image-textheme-dark);
                }

                &__text {
                    color: var(--msv-active-image-textheme-dark);
                }
            }
        }
    }

    &.texttheme__light .msc-active-image__details .msc-active-image__details__cta__link:hover,
    &.texttheme__light .msc-active-image__details .msc-active-image__details__cta__link:focus {
        color: var(--msv-active-image-textheme-dark);
        background-color: var(--msv-active-image-textheme-light);
    }

    &.texttheme__light .msc-active-image__details .msc-active-image__details__additional-content__cta__links:nth-child(1) {
        color: var(--msv-active-image-textheme-light);
        border-left: 1px solid var(--msv-active-image-textheme-light);
    }

    &.texttheme__dark .msc-active-image__details .msc-active-image__details__cta__link:hover,
    &.texttheme__dark .msc-active-image__details .msc-active-image__details__cta__link:focus {
        background-color: var(--msv-active-image-textheme-light);
    }

    &.texttheme__dark .msc-active-image__details .msc-active-image__details__additional-content__cta__links:nth-child(1) {
        color: var(--msv-active-image-textheme-dark);
        border-left: 1px solid var(--msv-active-image-textheme-dark);
    }
}
