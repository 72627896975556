$buybox-font-size: 14px;
$buybox-content-items-padding-top: 20px;
$buybox-content-items-padding-top-small: 12px;
$buybox-margin-top: 36px;
$buybox-button-height:32px;
$buybox-quantity-width:40px;
$buybox-find-in-store-description-padding-bottom:7px;
$buybox-product-title-line-height:32px;
$buybox-quantity-label-line-height:20px;
$msv-buybox-text-line-height: 21px;
$buybox-label-margin-bottom: 5px;
$buybox-title-margin-bottom: 5px;
$buybox-dropdown-height:32px;
$buybox-ratings-margin-top:10px;
$gutter-width: 40px;
$media-gallery-width:66%;
$msv-dialog-width: 400px;
$msv-dialog-button-margin: 15px auto;
$msv-dialog-button-padding: 6px 16px 7px;
$msv-dialog-button-width: 100%;
$buybox-custom-input-width:80px;
$buybox-custom-input-height:32px;

//style presets
:root {
    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xl);
    --msv-buybox-title-font-color: var(--msv-font-primary-color);
    --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-bg-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
}

// mixin

@mixin buybox-button {
    height: $buybox-button-height;
    width: 100%;
}

.ms-buybox {
    margin-top: $buybox-margin-top;

    @media (min-width: $msv-breakpoint-s) {
        display: flex;
        flex-flow: row;

        // content styles
        &__content {
            color: var(--msv-font-primary-color);
            font-size: $buybox-font-size;
            flex-grow: 2;
        }

        &__product-title {
            @include font-content($msv-font-weight-bold, var(--msv-buybox-title-font-size),
            $buybox-product-title-line-height);
            color: var(--msv-buybox-title-font-color);
            margin-bottom: $buybox-title-margin-bottom;
        }

        &__product-description {
            @include font-content($msv-font-weight-light, var(--msv-buybox-text-font-size),
            $msv-buybox-text-line-height);
            color: var(--msv-buybox-text-font-color);
        }

        &__dropdown,
        &__quantity {
            padding-top: $buybox-content-items-padding-top;
        }
        &__add-to-cart-box {
            width: 100%;
            padding-top: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            .ms-buybox__add-to-cart-box-button {
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-weight: 600;
                font-size: 16px;
                width: 180px;
                border-radius: 4px;
                padding: 8px 12px;
                color: #f2f2f2;
                background-color: #e41e2b !important;
                text-transform: uppercase;
                border: 2px solid #e41e2b;
                height: unset;
                &:not(:disabled) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        &__quantity-input-container {
            height: 40px;
            border:1px solid #ddd;
            border-radius:4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            &__minus, &__plus {
                font-size: 20px;
                width:32px;
                border-radius:4px;
                padding:8px 4px;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }
            &__input{
                width: 50px;
                text-align: center;
                font-size: 18px;
                border:none;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
            }
           
        }


        &__ratings-link {
            @include vfi();
            display: inline-block;
        }

        .msc-price__actual,
        .ms-buybox__dropdown-quantity-label,
        .ms-buybox__product-quantity-label-heading,
        .ms-buybox__key_in_price-label {
            color: var(--msv-buybox-title-font-color);
            font-weight: $msv-font-weight-600;
            line-height: $buybox-quantity-label-line-height;
        }

        .msc-price__actual {
            font-size: var(--msv-buybox-text-font-size);
        }

        .msc-price__strikethrough {
            @include font-content-xl(var(--msv-font-weight-light));
            margin: 0;
            color: $msv-gray-500;
        }

        .msc-price__pricerange {
            @include font-content-xl(var(--msv-font-weight-light));
            color: var(--msv-accent-brand-color);
        }

        &__media-gallery {
            display: block;
            flex-basis: $media-gallery-width;
            min-width: $media-gallery-width;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: $gutter-width;
        }

        .msc-no-ratings {
            margin-top: $buybox-ratings-margin-top;
        }

        .msc-dropdown__select {
            @include vfi();
            height: $buybox-dropdown-height;
            width: 100%;
        }

        .msc-quantity-input {
            @include vfi();
            height: $buybox-dropdown-height;
            text-align: center;
            width: $buybox-quantity-width;
        }

        .msc-price__salesagreementprompt_expanded,.msc-price__salesagreementprompt_collapsed {
            margin-top: 34px;
            color: var(--msv-accent-brand-color);
        }

        &__add-to-cart-container {
            padding-top: $buybox-content-items-padding-top;

            .msc-add-to-cart {
                @include buybox-button();
                @include primary-button(var(--msv-buybox-primary-btn-bg),
                var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
            }
        }

        &__add-to-wishlist-container {
            padding-top: $buybox-content-items-padding-top-small;

            .msc-add-to-wishlist {
                &::after {
                    color: var(--msv-buybox-btn-wishlist-color);
                }

                @include buybox-button();
                @include secondary-button();
                border-color: transparent;
                background-color: transparent;
                width: $buybox-button-height;
            }
        }

        &__find-in-store-description {
            padding-bottom: $buybox-find-in-store-description-padding-bottom;
        }

        &__find-in-store {
            padding-top: $buybox-content-items-padding-top;

            .ms-buybox__find-in-store-button {
                @include button-default();
                @include secondary-button(
                    var(--msv-buybox-secondary-btn-bg),
                    var(--msv-buybox-secondary-btn-font-color),
                    var(--msv-buybox-secondary-btn-border));
                font-size: var(--msv-buybox-btn-font-size);
                line-height: $msv-line-height-l;

                @include vfi();

                height: unset;

                &:not(:disabled) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        &__key_in_price_custom-amount__input {
            @include vfi();
            height: $buybox-custom-input-height;
            text-align: left;
            width: $buybox-custom-input-width;
        }
    }
    //mobile vp

    @media (max-width: $msv-breakpoint-m) {
        flex-flow: column;

        &__media-gallery {
            padding-right: 0;
        }
    }

    label {
        display: block;
        margin-bottom: $buybox-label-margin-bottom;
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border));
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
        margin-bottom: 20px;
    }

    &__shop-similar-descriptions-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border));
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
        margin-bottom: 20px;
    }
}

.msc-modal {
    .msc-modal__dialog.ms-buybox__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            max-width: $msv-dialog-width;
        }

        .ms-buybox__dialog__body {
            text-align: center;
        }

        .ms-buybox__dialog__image {
            margin-right: auto;
            margin-left: auto;
        }

        .ms-buybox__dialog__go-to-cart.msc-btn {
            @include primary-button(var(--msv-buybox-primary-btn-bg),
            var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }

        .ms-buybox__dialog__back-to-shopping.msc-btn {
            @include secondary-button(var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }
    }
}
