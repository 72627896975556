$rtl-nav-list-item-margin: 8px;
$rtl-nav-parent-menu-margin-left: 24px;

[dir="rtl"] {
    .ms-nav {
        @media screen and (min-width: $msv-breakpoint-xs) {
            &__list {
                &__mobile__container {
                    &__button {
                        @include add-icon($msv-ChevronRight);
                    }

                    &__span {
                        margin-right: 21px;
                    }
                }

                &__item__button {
                    text-align: right;

                    &::after {
                        float: left;
                        content: $msv-ChevronLeft;
                    }
                }
            }
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            .ms-nav__list {
                .ms-nav__list__item:first-child {
                    margin-right: 0;
                    margin-left: $rtl-nav-parent-menu-margin-left;
                }

                .havecateImage {
                    float: right;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            &__list {
                &__item__button {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}
