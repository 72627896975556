$msv-dialog-width: 400px;
$msv-dialog-button-margin: 15px auto;
$msv-dialog-button-padding: 6px 16px 7px;
$msv-dialog-button-width: 100%;

.msc-add-to-cart {
    @include primary-button();
    @include vfi();

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}

.msc-modal {
    .msc-modal__dialog.msc-add-to-cart__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            max-width: $msv-dialog-width;
        }

        .msc-add-to-cart__dialog__body {
            text-align: center;
        }

        .msc-add-to-cart__dialog__image {
            margin-right: auto;
            margin-left: auto;
        }

        .msc-add-to-cart__dialog__go-to-cart.msc-btn {
            @include primary-button();
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }

        .msc-add-to-cart__dialog__back-to-shopping.msc-btn {
            @include secondary-button();
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }
    }
}
