$msv-password-reset-width: 40%;
$msv-password-reset-width-m: 80%;
$msv-password-reset-message-margin-top: 20px;
$msv-password-reset-message-padding: 20px;
$msv-password-reset-success-border: 1px solid $msv-text-color;
$msv-password-reset-success-bg-color: $msv-gray-60;
$msv-password-reset-success-text-color: $msv-text-color;
$msv-password-reset-error-border: 1px solid $msv-error-color;
$msv-password-reset-error-bg-color: $msv-error-color;
$msv-password-reset-account-item-margin-top: 20px;
$msv-password-reset-input-margin-top: 4px;
$msv-password-reset-button-group-spacing: 32px;
$msv-password-reset-button-margin-top: 20px;

.ms-password-reset {
    @include font-content-m();
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-password-reset-width;
    }

    &__heading {
        @include font-content-xl();
    }

    &__account-item {
        margin-top: $msv-password-reset-account-item-margin-top;

        &-input {
            @include form-input-el();
            margin-top: $msv-password-reset-input-margin-top;
            width: 100%;
        }
    }

    &__page-success {
        color: $msv-password-reset-success-text-color;
        background-color: $msv-password-reset-success-bg-color;
        border: $msv-password-reset-success-border;
        display: none;
        margin-top: $msv-password-reset-message-margin-top;
        padding: $msv-password-reset-message-padding;
    }

    &__page-error {
        color: var(--msv-font-secondary-color);
        background-color: $msv-password-reset-error-bg-color;
        border: $msv-password-reset-error-border;
        display: none;
        margin-top: $msv-password-reset-message-margin-top;
        padding: $msv-password-reset-message-padding;
    }

    &__buttons {
        margin-top: $msv-password-reset-button-group-spacing;

        &__continue-button {
            margin-top: 0;
        }
    }

    &__continue-button {
        @include primary-button();
        margin-top: $msv-password-reset-button-margin-top;
        width: 100%;
    }

    &__cancel-button {
        @include secondary-button();
        margin-top: $msv-password-reset-button-margin-top;
        width: 100%;
    }

    &__verifying-modal,
    &__verifying-modal-message {
        display: none;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-width-m;
        }
    }
}
