$msv-checkout-express-min-height: 80px;
$msv-checkout-express-loading-dimension: 100%;
$msv-checkout-express-border: 0;
$msv-checkout-payment-ready-margin-bottom: 20px;
$msv-checkout-payment-ready-flex-basis: 100%;
$msv-checkout-payment-ready-margin-left: 10px;
$msv-checkout-payment-card-title-margin-bottom: 10px;
$msv-checkout-express-mutation-observer-margin-top: 20px;
$msv-checkout-express-heading-padding-bottom: 20px;
$msv-checkout-express-paypal-max-width: 300px;
$msv-checkout-express-padding-top: 28px;
$msv-checkout-express-googlepay-padding-top: 26px;
$msv-checkout-express-googlepay-max-width: 340px;
$msv-checkout-express-googlepay-in-cart-page-margin-top: -5px;
$msv-checkout-express-paypal-in-cart-page-margin-top: 10px;
$msv-checkout-express-container-padding-left: 0;
$msv-checkout-express-container-padding-right: 400px;
$msv-checkout-express-container-padding-top: 28px;
$msv-checkout-express-container-col-has-textblock-margin-top: 25px;
$msv-checkout-express-googlepay-mobile-margin-left: -24px;
$msv-checkout-express-googlepay-mobile-margin-top: -10px;

//style presets
:root {
    --msv-checkout-express-bg: #{$msv-gray-100};
    --msv-checkout-express-font-size: var(--msv-body-font-size-m);

    // heading
    --msv-checkout-express-heading-font-color: var(--msv-font-primary-color);
    --msv-checkout-express-heading-font-size: var(--msv-body-font-size-xl);
}

.checkout-express {
    position: relative;
    min-height: $msv-checkout-express-min-height;

    &__loading-background {
        display: flex;
        height: $msv-checkout-express-loading-dimension;
        position: absolute;
        width: $msv-checkout-express-loading-dimension;
        background-color: var(--msv-checkout-express-bg);

        .waiting {
            display: block;
            margin: auto;
        }
    }

    &__iframe {
        border: $msv-checkout-express-border;
        width: $msv-checkout-express-loading-dimension;
    }

    &__title {
        color: var(--msv-checkout-express-heading-font-color);
        font-style: normal;
        font-weight: $msv-font-weight-600;
        font-size: var(--msv-checkout-express-heading-font-size);
        line-height: $msv-line-height-xl;
        align-items: center;
        padding-bottom: $msv-checkout-express-heading-padding-bottom;
    }
}

.ms-checkout-express {
    &__overlay-modal {
        display: none;
    }
}

.ms-checkout-express-paypal {
    padding-top: $msv-checkout-express-padding-top;
    transform: scale(1.05);

    &-in-cart-page {
        margin-top: $msv-checkout-express-paypal-in-cart-page-margin-top;
        transform: scale(1.07);
    }

    .ms-checkout-express__add {
        max-width: $msv-checkout-express-paypal-max-width;
    }
}

.ms-checkout-express-googlepay {
    padding-top: $msv-checkout-express-googlepay-padding-top;
    transform: scale(0.92);

    &-in-cart-page {
        transform: scale(1.07);
        margin-top: $msv-checkout-express-googlepay-in-cart-page-margin-top;
    }

    .ms-checkout-express__add {
        max-width: $msv-checkout-express-googlepay-max-width;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        margin-left: $msv-checkout-express-googlepay-mobile-margin-left;
        margin-right: 0;
        margin-top: $msv-checkout-express-googlepay-mobile-margin-top;
        padding-top: 0;
    }
}

.container.msc-express-payment-container {
    padding-left: $msv-checkout-express-container-padding-left;
    padding-right: $msv-checkout-express-container-padding-right;

    .container-heading {
        color: var(--msv-checkout-express-heading-font-color);
        font-style: normal;
        font-weight: $msv-font-weight-600;
        font-size: var(--msv-checkout-express-heading-font-size);
        line-height: $msv-line-height-xl;
        align-items: center;
        width: 100%;
        padding-top: $msv-checkout-express-container-padding-top;
    }

    .col-md-has-textblock {
        width: 100%;
        margin-top: $msv-checkout-express-container-col-has-textblock-margin-top;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        padding-right: 0;
    }
}
