$msv-account-profile-wrapper-margin-left: 100px;
$msv-account-profile-wrapper-margin-right: 0;
$msv-account-profile-attributes-button-margin-left: 5px;
$msv-account-profile-attributes-button-margin-right: 0;
$msv-account-profile-section-margin-left-m: 0;

[dir="rtl"] {
    .ms-account-profile {
        &-wrapper {
            margin-right: $msv-account-profile-wrapper-margin-right;
            margin-left: $msv-account-profile-wrapper-margin-left;
        }

        .ms-account-profile__attributes {
            &-edit-button,
            &-save-button,
            &-cancel-button {
                margin-left: $msv-account-profile-attributes-button-margin-left;
                margin-right: $msv-account-profile-attributes-button-margin-right;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            display: flex;
            flex-direction: column;

            &-wrapper {
                margin-left: $msv-account-profile-section-margin-left-m;
            }
        }
    }
}
