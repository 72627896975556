$msv-details-heading-margin-bottom: 20px;
$msv-details-text-margin-right: 5px;

$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-qr-code-margin-top: 20px;
$msv-details-order-information-qr-code-margin-bottom: 20px;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-order-information-channel-name-margin-bottom: 12px;
$msv-details-order-information-channel-address-margin-bottom: 12px;

$msv-details-keep-shopping-btn-margin-bottom: 20px;

$msv-details-group-border: 1px solid $msv-black;
$msv-details-group-padding: 20px 0;
$msv-details-group-pickup-border: 1px solid $msv-gray-60;
$msv-details-group-delivery-items-text-color: $msv-gray-120;
$msv-details-group-delivery-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-group-delivery-address-padding-top: 20px;

$msv-details-sales-lines-width: calc(100% - 340px);
$msv-details-sales-status-left-desktop: 500px;
$msv-details-sales-status-left-mobile: 0;
$msv-details-sales-status-top: -133px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-padding: 20px 0;
$msv-details-sale-line-image-size: 147px;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-details-sale-line-buy-again-link-btn-margin-top: 5px;
$msv-details-sale-line-buy-again-link-btn-margin-left: 30px;

$msv-order-empty-image-width: 147px;

$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-font-weight: 600;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;

$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-help-label-margin-right: 4px;
$msv-details-payment-methods-margin-top: 20px;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 4px;
$msv-details-payment-methods-line-margin-bottom: 8px;
$msv-details-payment-methods-loyalty-gift-card-amount-margin-left: 4px;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;

$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;

$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;

$msv-msc-cart-line-width: 540px;
$msv-details-tracking-info-padding-top: 15px;
$msv-text-max-width:80%;
$msv-details-order-other-charge-margin-top:30px;

$msv-order-details-sales-line-checkbox-padding-top:64px;
$msv-order-details-sales-line-checkbox-padding-top-mobile:30px;

:root {
    // view mode
    --msv-order-details-view-mode-selected-btn-background: var(--msv-selected-button-background);
    --msv-order-details-view-mode-selected-hovered-btn-background: var(--msv-selected-hovered-button-background);
}

.ms-order-details {
    color: var(--msv-font-primary-color);

    &__heading {
        @include font-content-xl();
        color: $msv-gray-160;
        margin-bottom: $msv-details-heading-margin-bottom;
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;

        &-channel-name {
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: $msv-font-weight-600;
            line-height: $msv-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
        }

        &-channel-address {
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: $msv-font-weight-600;
            line-height: $msv-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-address-margin-bottom;
            margin-top: -$msv-details-order-information-channel-name-margin-bottom;
        }

        &-qr-code {
            align-items: center;
            background-color: var(--msv-bg-color);
            display: flex;
            height: $msv-details-order-information-qr-code-block-height;
            justify-content: center;
            margin-top: $msv-details-order-information-qr-code-margin-top;
            margin-bottom: $msv-details-order-information-qr-code-margin-bottom;
            width: $msv-details-order-information-qr-code-block-width;

            .qrcode-screenreader-text {
                clip: rect(1px, 1px, 1px, 1px);
                clip-path: inset(50%);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        &-channel-reference-id,
        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            display: block;

            @include font-content-l();
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
        }

        &-created-date {
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid $msv-black;
        }

        &-count {
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid $msv-black;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;

        @include secondary-button();
    }

    &__tracking-info {
        display: block;
    }

    &__sales-lines {
        border-top: 1px solid $msv-gray-90;
        flex: 3;

        &:first-child {
            border-top: none;
        }

        .ms-order-details__tracking-info {
            &:not(:first-child) {
                padding-top: $msv-details-tracking-info-padding-top;
            }
        }
    }

    &__sales-status {
        left: $msv-details-sales-status-left-desktop;
        top: $msv-details-sales-status-top;
        position: relative;
    }

    &__groups {
        border-top: 1px solid $msv-gray-160;
        border-bottom: 1px solid $msv-gray-160;
    }

    &__address {
        padding-top: $msv-details-group-delivery-address-padding-top;
        flex: 1;

        &-header {
            font-weight: bold;
            padding-bottom: 8px;
        }

        &-city, &-threeletterisoregionname {
            &::before {
                content: "\A";
                white-space: pre;
            }
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__group {
        display: flex;
        flex-wrap: wrap;

        &-delivery {
            padding-top: $msv-details-group-delivery-padding-top;

            flex: 1;
            padding-right: $msv-details-sale-line-spacing;

            &-heading {
                font-size: $msv-heading-text-size;
                line-height: $msv-heading-line-height;
                font-weight: $msv-font-weight-600;
                margin-right: $msv-details-text-margin-right;

                .ms-order-details__sales-status {
                    float: left;
                    top: 0;
                }
            }

            &-total-items {
                @include font-content-l();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }

        &:first-child {
            .ms-order-details__sales-lines,
            .ms-order-details__address {
                border-top: none;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line {
        padding: $msv-details-sale-line-padding;

        &-checkbox {
            padding-top: $msv-order-details-sales-line-checkbox-padding-top;
        }

        &-checkbox-control {
            display: flex;
            width: 100%;
        }

        .msc-cart-line {
            width: $msv-msc-cart-line-width;

            &__product {
                flex: none;
            }

            &__product-image img {
                max-width: $msv-details-sale-line-image-size;
                width: $msv-details-sale-line-image-size;
                height: $msv-details-sale-line-image-size;
            }

            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }

            &__product-title {
                font-size: $msv-text-size;
                line-height: $msv-text-line-height;
                max-width: $msv-text-max-width;
            }

            &__quantity {
                margin-left: 0;
                position: absolute;
                left: 0;
                bottom: 0;

                .quantity-label {
                    font-size: $msv-text-size;
                    line-height: $msv-text-line-height;
                }

                .quantity-value {
                    margin-left: $msv-details-text-margin-right;
                    display: inline;
                    color: $msv-gray-120;
                }
            }

            &__other-charges {
                position: absolute;
                margin-top: $msv-details-order-other-charge-margin-top;
            }

            &__product-price {
                position: absolute;
                right: 0;
                bottom: 0;
                width: unset;
                text-align: unset;
                margin-left: unset;

                .msc-price__strikethrough,
                .msc-price__actual {
                    font-size: $msv-text-size;
                    line-height: $msv-text-line-height;
                    color: $msv-gray-120;
                }
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-button();
        }

        &-btn-buy-it-again-link {
            margin-left: $msv-details-sale-line-buy-again-link-btn-margin-left;
            margin-top: $msv-details-sale-line-buy-again-link-btn-margin-top;
        }
    }

    &__order-summary {
        float: right;
        width: $msv-details-footer-block-width;
        margin-top: $msv-details-order-summary-spacing;

        &-heading {
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-order-summary-heading-margin-bottom;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
        }

        &-line-total-amount {
            border-top: 1px solid $msv-gray-160;
            padding-top: $msv-details-order-summary-line-spacing;
            margin-bottom: $msv-details-order-summary-line-spacing;

            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-600;
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-600;
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        float: left;
        margin-top: $msv-details-help-margin-top;

        &-label {
            margin-right: $msv-details-help-label-margin-right;
        }

        &-title {
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            @include vfi();
        }
    }

    &__payment-methods {
        float: left;
        width: $msv-details-footer-block-width;
        margin-top: $msv-details-payment-methods-margin-top;
        margin-right: $msv-details-payment-methods-margin-right;

        &-title {
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-margin-bottom;
        }

        &-card-amount,
        &-loyalty-amount,
        &-gift-card-amount,
        &-customer-account-amount,
        &-cash-amount {
            margin-left: $msv-details-payment-methods-loyalty-gift-card-amount-margin-left;
        }
    }

    &__delivery-container {
        flex: 1;
    }

    &__pickup-date-timeslot {
        padding-bottom: 20px;
        padding-top: 20px;

        &-title {
            font-weight: var(--msv-font-weight-bold);
            padding-bottom: 8px;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;
        }

        &__sales-line {
            &-checkbox {
                padding-top: $msv-order-details-sales-line-checkbox-padding-top-mobile;
            }
        }

        &__sales-status {
            float: right;
            left: $msv-details-sales-status-left-mobile;
            top: 0;
        }

        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            width: 100%;
            padding-right: 0;
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;
        }

        .msc-cart-line {
            width: 100%;
        }
    }

    @include image($msv-order-empty-image-width);

    &__table-view-actions {
        margin-top: 50px;
        margin-bottom: 10px;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: grid;
            margin-top: 60px;
            margin-bottom: 20px;
        }

        &__buy-selected {
            @include primary-button();
        }

        &__enable-selection {
            @include primary-button();
        }

        &__disable-selection {
            @include secondary-button();
            margin-left: 8px;

            @media screen and (max-width: $msv-breakpoint-m) {
                margin-left: unset;
                margin-top: 5px;
            }
        }
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            &__row {
                height: 50px;

                th {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        &__row {
            height: 54px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;

            &__product-info {
                display: grid;
            }

            &__product-name {
                font-weight: bold;
            }

            &__product-image-container {
                @include image(64px);
            }

            &__buy-again {
                @include add-icon($msv-shopping-bag, before);

                font-size: $msv-font-size-l;
                color: $msv-primary;
                background-color: transparent;
                width: 48px;
                height: 48px;
                padding-left: 0;
                padding-right: 0;
            }

            &__extra-actions-cell {
                &__toggle {
                    @include add-icon($msv-Ellipses-V);
                    background: none;
                    height: 82px;
                }

                &__actions-container {
                    align-items: flex-start;
                    background: $msv-white;
                    border-radius: 2px;
                    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);
                    flex-direction: column;
                    height: auto;
                    padding-left: 0;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    z-index: 10;

                    &__view-details {
                        @include add-icon($msv-List);
                    }

                    &__buy-again {
                        @include add-icon($msv-shopping-bag, before);
                    }

                    button {
                        background: none;
                        height: 60px;
                        padding: 0 8px;
                        text-align: left;
                        width: 100%;

                        &::before {
                            margin-right: 12px;
                            width: 16px;
                            color: $msv-primary;
                        }
                    }
                }
            }
        }
    }

    &__ms-order-details-view-modes {
        float: right;

        button {
            margin: 0 6px;
            padding: 0 14px;
            color: $msv-primary;
            background: none;

            &:hover, &.msc-view-modes__selected {
                background: var(--msv-order-details-view-mode-selected-btn-background);
            }

            &:hover.msc-view-modes__selected {
                background-color: var(--msv-order-details-view-mode-selected-hovered-btn-background);
            }
        }

        &__list-view {
            @include add-icon($msv-List);
        }

        &__detailed-view {
            @include add-icon($msv-TH-Large);
        }
    }

    .checkbox-container {
        align-items: center;
        vertical-align: middle;
        display: flex;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 19px;

        .checkbox-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            left: 6px;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 19px;
            width: 19px;
            background-color: transparent;
            border: 1px solid $msv-primary;
            border-radius: 1rem;
        }

        .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2.25px;
            width: 5px;
            height: 10px;
            border: solid $msv-yellow;
            background-color: $msv-primary;
            border-width: 0 1.75px 1.75px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        input:checked ~ .checkmark {
            background-color: $msv-primary;
        }

        input:checked ~ .checkmark::after {
            display: block;
        }
    }
}
