$msv-checkout-payment-instrument-min-height: 80px;
$msv-checkout-payment-instrument-card-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-top: 20px;

$msv-checkout-payment-instrument-loading-dimension: 100%;
$msv-checkout-payment-instrument-border: 0;

$msv-checkout-payment-container-padding: 0;
$msv-checkout-payment-container-margin-zero: 0;

$msv-checkout-payment-ready-margin-bottom: 36px;
$msv-checkout-payment-ready-flex-basis: 100%;
$msv-checkout-payment-ready-margin-left: 12px;

$msv-checkout-payment-instrument-paypal-max-width: 300px;
$msv-checkout-payment-instrument-googlepay-max-width: 340px;

.checkout-payment-instrument {
    position: relative;
    min-height: $msv-checkout-payment-instrument-min-height;

    &__loading-background {
        display: flex;
        height: $msv-checkout-payment-instrument-loading-dimension;
        position: absolute;
        width: $msv-checkout-payment-instrument-loading-dimension;
        background-color: $msv-gray-20;

        .waiting {
            display: block;
            margin: auto;
        }
    }

    &__iframe {
        border: $msv-checkout-payment-instrument-border;
        width: $msv-checkout-payment-instrument-loading-dimension;
    }

    &__show {
        &-card-title {
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
        }

        &-billing-address-title {
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &-card-name,
        &-card-number,
        &-card-expiration,
        &-billing-address-1,
        &-billing-address-2,
        &-billing-address-3 {
            margin-bottom: $msv-checkout-payment-container-margin-zero;
        }
    }
}

.ms-checkout-payment-instrument {
    &__overlay-modal {
        display: none;
    }

    .ms-checkout-payment-instrument__error {
        clear: both;
    }
}

.ms-checkout-payment-instrument-paypal {
    .ms-checkout-payment-instrument__add {
        max-width: $msv-checkout-payment-instrument-paypal-max-width;
        float: left;
    }
}

.ms-checkout-payment-instrument-googlepay {
    transform: scale(0.92);

    .ms-checkout-payment-instrument__add {
        max-width: $msv-checkout-payment-instrument-googlepay-max-width;
        float: left;
    }
}

.ms-checkout-section-container {
    padding-left: $msv-checkout-payment-container-padding;
    padding-right: $msv-checkout-payment-container-padding;
}

.ms-checkout__guided-card.ready {
    .ms-checkout-payment-instrument {
        margin-bottom: $msv-checkout-payment-ready-margin-bottom;

        &__show {
            display: flex;
            flex-flow: row wrap;
        }

        &__card-title {
            font-weight: $msv-font-weight-600;
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
            flex-basis: $msv-checkout-payment-ready-flex-basis;
        }

        &__billing-address-title {
            font-weight: $msv-font-weight-600;
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
            flex-basis: $msv-checkout-payment-ready-flex-basis;
        }

        &__card-name {
            display: none;
        }

        &__card-expiration,
        &__billing-address-1,
        &__billing-address-2,
        &__billing-address-3 {
            margin-left: $msv-checkout-payment-ready-margin-left;
        }
    }
}
