$border-bottom: 1px solid $msv-gray-60;
$msv-business-partners-padding-bottom: 28px;
$msv-business-partners-header-padding-bottom: 20px;
$msv-business-partners-header-border-bottom: $border-bottom;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-business-partners-message-margin-top: 20px;
$msv-business-partners-message-margin-bottom: 20px;
$msv-business-partners-back-link-line-height: 12px;
$msv-business-partners-row-height: 54px;
$msv-business-partners-select-button-width: 30px;
$msv-business-partners-search-margin: 20px;
$msv-business-partners-search-padding: 10px;
$msv-business-partners-search-height: 48px;

.ms-business-partners {
    color: var(--msv-font-primary-color);
    padding-bottom: $msv-business-partners-padding-bottom;

    &__empty-message,
    &__loading,
    &__alert {
        display: block;
        margin-top: $msv-business-partners-message-margin-top;
        margin-bottom: $msv-business-partners-message-margin-bottom;
    }

    &__heading {
        @include font-content-xl();
        display: inline-block;
    }

    &__header {
        padding-bottom: $msv-business-partners-header-padding-bottom;
        border-bottom: $msv-business-partners-header-border-bottom;
        display: flex;
        flex-wrap: wrap;
    }

    &__back-link {
        cursor: pointer;
        display: block;
        line-height: $msv-business-partners-back-link-line-height;
        margin-top: $msv-business-partners-message-margin-top;
        margin-bottom: $msv-business-partners-message-margin-bottom;

        @include add-icon($msv-ChevronLeft, before);

        &::before {
            padding: 0 5px;
        }
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            @include divider(bottom);

            &__row {
                height: $msv-business-partners-row-height;

                th {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        &__row {
            height: $msv-business-partners-row-height;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;

            @include divider(bottom);

            &__column-select-button {
                width: $msv-business-partners-select-button-width;
            }

            &__select-button {
                @include secondary-button();
                @include add-icon($msv-ChevronRight, before);
                padding: 0;
                border: none;
                min-width: $msv-business-partners-select-button-width;
                height: $msv-business-partners-row-height;
            }
        }

        &__search {
            margin-top: $msv-business-partners-search-margin;
            margin-bottom: $msv-business-partners-search-margin;
            width: 100%;
            display: flex;
            justify-content: space-between;
            order: 1;
        }

        &__searchBar {
            height: $msv-business-partners-search-height;
            width: 100%;
        }

        &__searchButton {
            @include add-icon($msv-Search);

            background-color: var(--msv-accent-brand-color);
            border: 1px solid var(--msv-accent-brand-color);
            color: var(--msv-font-secondary-color);
            padding: $msv-business-partners-search-padding;
            width: $msv-business-partners-search-height;
            height: $msv-business-partners-search-height;
        }

        &__load-more {
            cursor: pointer;
            margin-top: $msv-business-partners-message-margin-top;
            text-align: center;
        }
    }
}
